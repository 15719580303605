import $http from './index'


export function getCommunity(page) {
    return $http.get('/Notice/GetNoticeList', {
        params: {
            rows: 15,
            page: page
        }
    })
}

export function postAddCommunity(title, content, imgName, status, id) {
    return $http.post('/Notice/AddNotice', {
        title: title,
        content: content,
        imgName: imgName,
        status: status,
        id: id
    })
}

//通知预览

export function getPreview(content){
    return $http.post('/Notice/GetNoticeContent',{
    
            content
       
    })
}